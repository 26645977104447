import { Link, useLocation } from 'react-router-dom'
import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useIsFeatureEnabled } from '../../hooks/useFeatures.ts'

export function AnalyticsPageSelector(){
  const { pathname } = useLocation()
  const externalSharingEnabled = useIsFeatureEnabled("external-sharing")
  return (
    <Stack direction="row" justifyContent="end" mb={2}>
      <ToggleButtonGroup
        size="small"
        color="primary"
        value={pathname}
        exclusive
        aria-label="Analytics Type"
      >
        {externalSharingEnabled && (
          <ToggleButton value="/analytics/explore/shares" sx={{ px: 1, py: "2px" }} component={Link} to="/analytics/explore/shares">Shares</ToggleButton>
        ) }
        <ToggleButton value="/analytics/explore/assets" sx={{ px: 1, py: "2px" }} component={Link} to="/analytics/explore/assets">Assets</ToggleButton>
        <ToggleButton value="/analytics/explore/users" sx={{ px: 1, py: "2px" }} component={Link} to="/analytics/explore/users">Users</ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  )
}
