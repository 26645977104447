import api from '@api'
import {
  CreateAssetExternalShareRequest,
  CreateAssetExternalShareResponse, GetAssetExternalSharePasswordResponse,
  GetAssetExternalShareResponse,
  GetAssetExternalSharesResponse,
} from '../@types/asset-external-share.ts'

export async function getAssetExternalShares(assetId: string) {
  const response = await api.get<GetAssetExternalSharesResponse>(`/api/v1/assets/${assetId}/shares/external`)
  return response.data
}

export async function getAssetExternalShare(assetId: string, externalShareId: string) {
  const response = await api.get<GetAssetExternalShareResponse>(`/api/v1/assets/${assetId}/shares/external/${externalShareId}`)
  return response.data
}

export async function getPasswordForAssetExternalShare(assetId: string, externalShareId: string) {
  const response = await api.get<GetAssetExternalSharePasswordResponse>(`/api/v1/assets/${assetId}/shares/external/${externalShareId}/password`)
  return response.data
}


export async function createAssetExternalShare(assetId: string, req: CreateAssetExternalShareRequest) {
  const response = await api.post<CreateAssetExternalShareResponse>(
    `/api/v1/assets/${assetId}/shares/external`,
    req,
  )
  return response.data
}

export async function deleteAssetExternalShare(assetId: string, externalShareId: string) {
  const response = await api.delete(`/api/v1/assets/${assetId}/shares/external/${externalShareId}`)
  return response.data
}