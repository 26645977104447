import { GeneralAccessType } from '../sharing'

export type AdminAnalyticsRequestOptions = {
  sortBy: AdminAnalyticsSortBy,
  sortDirection: AdminAnalyticsSortDirection,
  filters: AnalyticsFilter[],
  limit: number,
  offset: number
}

export type AdminAnalyticsSummariesRequestOptions = {
  assetIds: string[],
  analyticType: AnalyticType,
  periodStart: Date | null | undefined,
  periodEnd: Date | null | undefined,
}

export enum AnalyticsFilterType {
  BOUNDING_DATE = 'BOUNDING_DATE',
  HAS_CATEGORY = 'HAS_CATEGORY',
  N_HAS_CATEGORY = 'N_HAS_CATEGORY',
  OCCURRENCE = 'OCCURRENCE',
  SHARING = 'SHARING',
  NOOP = 'NOOP'
}

export type BoundingDateFilter = {
  filterType: AnalyticsFilterType.BOUNDING_DATE
  start: Date | null,
  end: Date | null
}

export type HasCategoryValueFilter = {
  filterType: AnalyticsFilterType.HAS_CATEGORY
  categoryValueIds: string[]
}

export type DoesNotHaveCategoryValueFilter = {
  filterType: AnalyticsFilterType.N_HAS_CATEGORY
  categoryValueIds: string[]
}

export type OccurenceFilter = {
  filterType: AnalyticsFilterType.OCCURRENCE
  event: AnalyticType
  operator: OccurrenceOperator
  numOccurrences: number
}

export type SharingFilter = {
  filterType: AnalyticsFilterType.SHARING
  sharingType: GeneralAccessType
}


export type AnalyticsFilter =
  BoundingDateFilter
  | HasCategoryValueFilter
  | DoesNotHaveCategoryValueFilter
  | OccurenceFilter

export enum CategoryValueFilterMode {
  ALL_OF = 'ALL_OF',
  ONE_OF = 'ONE_OF'
}

export type OccurrenceOperator = 'eq' | 'lt' | 'gt'

export type AdminAnalyticsSearchResponse = {
  results: AdminAnalyticsAssetResult[],
  totalResultCount: number,
  totalFavorites: number,
  totalDownloads: number,
  totalShares: number,
  totalViews: number,
}

export type AdminAnalyticsSummariesResponse = {
  summaries: { [key: string]: GraphSummary }
}

export type GraphSummary = {
  assetId: string,
  entries: SummaryEntry[]
}

export type SummaryEntry = {
  bucketStart: Date,
  bucketEnd: Date,
  count: number
}

export type AdminAnalyticsExportResponse = {
  fileDownloadId: string
}


export type AdminAnalyticsAssetResult = {
  assetId: string,
  assetPreviewId: string | null,
  name: string,
  createdAt: string,
  numFavorites: number,
  numDownloads: number,
  numViews: number,
  numShares: number,
  createdByName: string,
  categoryValueUUIDs: string[],
}

export enum AdminAnalyticsSortBy {
  NUM_DOWNLOADS = 1,
  NUM_SHARES = 2,
  NUM_VIEWS = 3,
  CREATED_DATE = 4,
  NUM_FAVORITES = 5,
  LAST_SESSION_DATE = 6,
  NUM_OPENS = 7
}

export type AdminAnalyticsSortDirection = 'asc' | 'desc'

export enum AnalyticType {
  DOWNLOAD = 1,
  SHARE = 2,
  VIEW = 3,
  FAVORITE = 4
}

export const ANALYTIC_TYPE_BY_SORT = {
  [AdminAnalyticsSortBy.NUM_DOWNLOADS]: AnalyticType.DOWNLOAD,
  [AdminAnalyticsSortBy.NUM_SHARES]: AnalyticType.SHARE,
  [AdminAnalyticsSortBy.NUM_VIEWS]: AnalyticType.VIEW,
  [AdminAnalyticsSortBy.CREATED_DATE]: null,
  [AdminAnalyticsSortBy.NUM_FAVORITES]: AnalyticType.FAVORITE,
  [AdminAnalyticsSortBy.LAST_SESSION_DATE]: null,
  [AdminAnalyticsSortBy.NUM_OPENS]: null
}