import { useContext } from 'react'
import Pagination from '../../pagination/Pagination'
import { ShareAnalyticsContext } from '../../../contexts/ShareAnalyticsContext.tsx'
import { useShareAnalytics } from '../../../hooks/analytics/useShareAnalytics.ts'

export default function ShareAnalyticsPagination() {
  const { filters: { currentPage, pageSize }, setPage } = useContext(ShareAnalyticsContext)
  const { data } = useShareAnalytics()
  const totalResultCount = data?.totalResultCount || 0
  const handlePageChange = (pageNum: number) => setPage(pageNum)
  return (
    <Pagination
      currentPage={currentPage}
      pageSize={pageSize}
      totalResultCount={totalResultCount}
      onPageChange={handlePageChange}
      scrollOnPageChange={true}
    />
  )
}