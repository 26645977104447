import { useTheme } from '@mui/material/styles'
import { Avatar, Collapse, Stack, TableCell, Typography } from '@mui/material'
import { fNumber } from '../../../utils/formatNumber'
import { useState } from 'react'
import { StripedTableRow } from '../common/StripedTableRow'
import Iconify from '../../Iconify'
import { AnalyticsShareResult } from '../../../@types/analytics/analytics-share.ts'
import { NumberTableCell } from '../common/NumberTableCell.tsx'

export function ShareAnalyticsTableRow({ share, rowNum }: { share: AnalyticsShareResult, rowNum: number }) {
  // hooks
  const theme = useTheme()
  const [expanded, setExpanded] = useState<boolean>(false)

  // handlers
  const handleExpand = () => setExpanded(!expanded)

  // calculated
  const isStriped = rowNum % 2 == 0
  const iconRotation = expanded ? '90deg' : '0deg'

  return (
    <>
      <StripedTableRow isStriped={isStriped}>
        <TableCell sx={{ py: 1.5 }}>
          <Stack direction='row' spacing={2} alignItems='center' sx={{ height: 48 }}>
            <Iconify
              onClick={handleExpand}
              icon='eva:chevron-right-fill'
              sx={{
                fontSize: 20,
                transition: '100ms all ease-in-out',
                rotate: iconRotation,
                cursor: 'pointer',
              }}
            />
            <Avatar sx={{ height: 32, width: 32, bgcolor: theme.palette.bg.mintDark }}>{share.name[0]}</Avatar>
            <Typography>{share.name}</Typography>
          </Stack>
        </TableCell>

        <NumberTableCell>
          <Typography variant='small'>{fNumber(share.numDownloads)}</Typography>
        </NumberTableCell>

        <NumberTableCell>
          <Typography variant='small'>{fNumber(share.numViews)}</Typography>
        </NumberTableCell>
      </StripedTableRow>

      {
        expanded && (
          <StripedTableRow isStriped={isStriped}>
            <TableCell colSpan={7}>
              <Collapse in={expanded} timeout='auto' unmountOnExit>
                todo!
                {/*<UserAnalyticsTableRowDetails user={user} />*/}
              </Collapse>
            </TableCell>
          </StripedTableRow>
        )
      }
    </>

  )
}


