import Page from '../../components/Page'
import { ShareAnalyticsProvider } from '../../contexts/ShareAnalyticsContext.tsx'
import { ShareAnalyticsTable } from '../../components/analytics/share/ShareAnalyticsTable.tsx'

export default function GlobalShareAnalytics() {
  return (
    <Page title='Sharing Analytics'>
      <ShareAnalyticsProvider>
        <ShareAnalyticsTable />
      </ShareAnalyticsProvider>
    </Page>
  )
}