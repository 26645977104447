import { HostedContentRoomAsset } from '../../../../@types/hosted-content-room.ts'
import { AppBar, Box, Stack, Toolbar, Typography } from '@mui/material'
import { PoweredByMasset } from '../../PoweredByMasset.tsx'
import { DocumentPreviewItem } from './DocumentPreviewItem.tsx'
import { AssetPreview } from '../../../../@types/asset.ts'
import { ConsumeButton } from './ConsumeButton.tsx'
import { useTheme } from '@mui/material/styles'

export function DefaultAssetSinglePageDisplay({ asset, preview }: {
  asset: HostedContentRoomAsset,
  preview: AssetPreview
}) {
  const theme = useTheme()

  return (
    <Stack>
      <AppBar
        position='fixed'
        sx={{
          top: theme.mixins.toolbar.minHeight,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar
          sx={{
            height: 1,
            px: '40px !important',
          }}
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{ width: '100%' }}
          >
            <Box></Box>
            <Box textAlign='center'>
              <Typography variant='h2'>{asset.name}</Typography>
            </Box>
            <Box>
              <ConsumeButton asset={asset} />
            </Box>
          </Stack>
        </Toolbar>

      </AppBar>
      <Box sx={{ display: 'flex' }}>

        <Box
          component='main'
          // this matches the header height
          mt={16}
          p={8}
          sx={{
            flexGrow: 1,
            minHeight: 'calc(100vh - 128px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >

          <Stack spacing={10} sx={{ flex: 1 }}>
              <DocumentPreviewItem
                asset={asset}
                preview={preview}
              />
          </Stack>

          <Box mt={6} sx={{ maxWidth: 450 }}>
            <PoweredByMasset />
          </Box>

        </Box>
      </Box>
    </Stack>
  )
}