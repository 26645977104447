import { TableCell, TableHead, TableRow } from '@mui/material'
import { useContext } from 'react'
import { AdminAnalyticsSortBy } from '../../../@types/analytics/analytics-asset'
import { AnalyticsSortableTableCell } from '../AnalyticsSortableTableCell'
import Iconify from '../../Iconify'
import { ShareAnalyticsContext } from '../../../contexts/ShareAnalyticsContext.tsx'
import { PLACEHOLDER_SHARE_ANALYTICS, useShareAnalytics } from '../../../hooks/analytics/useShareAnalytics.ts'

export default function ShareAnalyticsTableHead() {
  const {
    filters: { sortBy, sortDirection },
    setSort,
  } = useContext(ShareAnalyticsContext)
  const { data } = useShareAnalytics()
  const {
    totalOpens,
    totalDownloads,
  } = data || PLACEHOLDER_SHARE_ANALYTICS

  return (
    <TableHead>
      <TableRow>
        {/* avatar cell */}
        {/* name / tags */}
        <TableCell width='100%'></TableCell>

        <AnalyticsSortableTableCell
          label='Downloads'
          col={AdminAnalyticsSortBy.NUM_DOWNLOADS}
          icon={<Iconify icon='eva:download-outline' />}
          total={totalDownloads}
          curSortBy={sortBy}
          curSortDirection={sortDirection}
          setSort={setSort}
        />

        <AnalyticsSortableTableCell
          label='Opens'
          col={AdminAnalyticsSortBy.NUM_OPENS}
          icon={<Iconify icon='ph:envelope-open' />}
          total={totalOpens}
          curSortBy={sortBy}
          curSortDirection={sortDirection}
          setSort={setSort}
        />
      </TableRow>
    </TableHead>
  )
}


