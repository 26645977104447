import { IconButton, Tooltip } from '@mui/material'
import Iconify from '../../../Iconify.tsx'
import { useHostedContentRoomDownload } from '../../../../hooks/useDownload.tsx'
import { HostedContentRoomAsset } from '../../../../@types/hosted-content-room.ts'
import { AssetStorageType } from '../../../../@types/asset.ts'

export function ConsumeButton({asset}: {asset: HostedContentRoomAsset}){
  const { consumeAsset } = useHostedContentRoomDownload()
  const handleConsumeClick = () => consumeAsset(asset.roomShortCode, asset)
  const tooltip = asset.storageType == AssetStorageType.INTERNAL_S3 ? "Download" : "Open Link"
  const icon = asset.storageType == AssetStorageType.INTERNAL_S3 ? "ph:download" : "ph:link-simple-horizontal"
  return (
    <Tooltip title={tooltip}>
      <IconButton
        onClick={handleConsumeClick}
      >
        <Iconify icon={icon} />
      </IconButton>
    </Tooltip>
  )
}