import { UserType } from "../@types/user";
import { ReactNode } from "react";
import useAuth from "../hooks/useAuth";

type Props = {
  roles: UserType[]
  children: ReactNode;
};

export function VisibleForRole({ roles, children }: Props) {
  const { user } = useAuth();
  const isPermitted = user && roles.includes(user.userType)
  if(!isPermitted) {
    return <></>;
  }
  return <>{children}</>;
}

export function HiddenForRole({ roles, children }: Props) {
  const { user } = useAuth();
  const shouldHide = !user || roles.includes(user.userType)
  if(shouldHide) {
    return <></>;
  }
  return <>{children}</>;
}
