import { Avatar, Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material'
import { SharingHoverableItem } from '../field/SharingHoverableItem.tsx'
import Iconify from '../../../Iconify.tsx'
import { AssetExternalShare } from '../../../../@types/asset-external-share.ts'
import { AVATAR_SIZE } from '../../../admin/groups/field/GroupMembersField.tsx'
import { useTheme } from '@mui/material/styles'
import { useBaseUrls } from '../../../../hooks/useBootstrap.ts'
import { useSnackbar } from 'notistack'
import { useDeleteAssetExternalShare } from '../../../../hooks/useAssetExternalShares.ts'
import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { getPasswordForAssetExternalShare } from '../../../../clients/AssetExternalShareClient.ts'

export function ExternalShareItem({ share }: { share: AssetExternalShare }) {
  const theme = useTheme()
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar()
  const { sharingBaseUrl } = useBaseUrls()
  const { mutateAsync: deleteExternalShare, isLoading } = useDeleteAssetExternalShare()

  // handlers
  const handleRemove = () => setConfirmOpen(true)
  const handleRemoveConfirm = () => {
    deleteExternalShare({
      assetId: share.assetId,
      externalShareId: share.externalShareId
    })
  }

  const handleLinkCopy = () => {
    const link = `${sharingBaseUrl}/cr/${share.shortCode}/a/${share.assetShortCode}`
    window.navigator.clipboard.writeText(link).then(() => {
      enqueueSnackbar((
        <Typography variant="standard">Link copied!</Typography>
      ), { autoHideDuration: 2000 })
    })
  }

  const handlePasswordCopy = () => {
    getPasswordForAssetExternalShare(share.assetId, share.externalShareId).then(data => {
      window.navigator.clipboard.writeText(data.password).then(() => {
        enqueueSnackbar((
          <Typography variant="standard">Password copied!</Typography>
        ), { autoHideDuration: 2000 })
      })
    })

  }

  return (
    <SharingHoverableItem>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={2} alignItems='center'>
          <Avatar
            sx={{ height: AVATAR_SIZE, width: AVATAR_SIZE, bgcolor: theme.palette.bg.mintHoverEmphasized }}>
            <Iconify icon='ph:link-simple' />
          </Avatar>
          <Stack direction='column'>
            <Typography>{share.name}</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Button
                size="small"
                variant="text"
                startIcon={<Iconify icon='ph:link-simple-horizontal' />}
                onClick={handleLinkCopy}
              >
                Copy link
              </Button>
              {
                share.isPasswordProtected && (
                  <Button
                    size="small"
                    variant="text"
                    startIcon={<Iconify icon='ph:lock' />}
                    onClick={handlePasswordCopy}
                  >
                    Copy Password
                  </Button>
                )
              }
            </Stack>

          </Stack>
        </Stack>
        <Iconify
          icon='eva:close-outline'
          onClick={handleRemove}
          sx={{ cursor: 'pointer' }}
        />
      </Stack>

      <Dialog
        open={confirmOpen}
        maxWidth="xs"
        fullWidth={true}
        onClose={() => setConfirmOpen(false)}
      >
        <DialogTitle>
          <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={() => setConfirmOpen(false)}>
              <Iconify icon='eva:close-fill' />
            </IconButton>
          </Box>

          <Typography variant='h2' paragraph>Are you sure?</Typography>

        </DialogTitle>

        <DialogContent>
          <Typography variant="standard" mb={4} component="div">
            If you delete this external share, users will no longer be able to
            access content using the associated link.
          </Typography>
          <Stack spacing={2}>
            <LoadingButton
              loadingPosition="start"
              loading={isLoading}
              fullWidth={true}
              variant="contained"
              color="error"
              size="large"
              onClick={handleRemoveConfirm}
            >
              Delete
            </LoadingButton>
            <Button
              fullWidth={true}
              variant="outlined"
              size="large"
              onClick={() => {
                setConfirmOpen(false);
              }}
            >
              Cancel
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </SharingHoverableItem>
  )
}