import { HostedContentRoomAsset } from '../../../../@types/hosted-content-room.ts'
import { AppBar, Box, IconButton, Stack, Toolbar, Typography } from '@mui/material'
import { AssetPreview } from '../../../../@types/asset.ts'
import Iconify from '../../../Iconify.tsx'
import { useState } from 'react'
import { ActivePreviewProvider } from './context/ActivePreviewContext.tsx'
import { DocumentPreviewSection } from './DocumentPreviewSection.tsx'
import { SidebarDrawer } from './SidebarDrawer.tsx'
import { ConsumeButton } from './ConsumeButton.tsx'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

export function DefaultAssetMultiPageDisplay({ asset, previews }: {
  asset: HostedContentRoomAsset,
  previews: AssetPreview[]
}) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState<boolean>(!isSmallScreen)

  // handlers
  const handleDrawerToggle = () => setDrawerOpen((prev) => !prev)

  // calculated props
  const renderDrawer = drawerOpen && !isSmallScreen
  return (
    <Stack>
      <AppBar
        position='fixed'
        sx={{
          top: theme.mixins.toolbar.minHeight,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar
          sx={{
            height: 1,
            px: '40px !important',
          }}
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{ width: '100%' }}
          >
            <Box>
              {
                !isSmallScreen && (
                  <IconButton
                    onClick={handleDrawerToggle}
                    sx={{ ml: -1 }}
                  >
                    <Iconify icon='ph:list' />
                  </IconButton>
                )
              }
            </Box>
            <Box textAlign='center'>
              <Typography variant='h2'>{asset.name}</Typography>
            </Box>
            <Box>
              <ConsumeButton asset={asset} />
            </Box>
          </Stack>
        </Toolbar>

      </AppBar>

      <ActivePreviewProvider>
        <Box sx={{ display: 'flex' }}>
          <SidebarDrawer open={renderDrawer} asset={asset} previews={previews} />
          <DocumentPreviewSection asset={asset} previews={previews} />
        </Box>
      </ActivePreviewProvider>
    </Stack>
  )
}