import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getNotificationSubscriptionSummary, upsertNotificationSubscription } from '../clients/NotificationClient.ts'
import { NotificationChannel, NotificationType } from '../@types/notifications.ts'

export function useNotificationSubscriptionSummary() {
  return useQuery({
    queryKey: ['notification-subscriptions', 'summary'],
    queryFn: () => getNotificationSubscriptionSummary(),
  })
}

type UpsertNotificationSubscriptionParams = {
  type: NotificationType,
  channels: NotificationChannel[]
}

export function useUpsertNotificationSubscription() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (params: UpsertNotificationSubscriptionParams) => upsertNotificationSubscription(params.type, params.channels),
    onSuccess: () => queryClient.invalidateQueries(['notification-subscriptions', 'summary'])
  })
}