import Page from '../../components/Page.tsx'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { ReactNode } from 'react'
import { NotificationChannel, NotificationType } from '../../@types/notifications.ts'
import { useNotificationSubscriptionSummary } from '../../hooks/useNotificationSubscriptions.ts'
import { SubscriptionChannelEditButton } from '../../components/notifications/SubscriptionChannelEditButton.tsx'
import { VisibleForRole } from '../../guards/VisibleForRole.tsx'
import { UserType } from '../../@types/user.ts'

export function ProfileNotifications() {
  const { data: summary, isLoading, isError } = useNotificationSubscriptionSummary()
  if (isLoading || isError) return <></>
  return (
    <Page title='Notification Settings'>
      <Typography
        variant='h2'
      >
        Notifications
      </Typography>
      <Divider sx={{ mt: 2, mb: 3 }} />

      <Stack spacing={4}>

        <VisibleForRole roles={[UserType.ADMIN]}>
          <SubscriptionCategory title='Assets'>
            <Subscription
              title='New Asset Requests'
              description='Notifications when an asset is requested by Masset users'
              notificationType={NotificationType.ASSET_NEW_REQUEST}
              channels={summary.assetNewRequest.channels}
            />
            <Divider />

            <Subscription
              title='Feedback'
              description='Notifications when users provide asset feedback.'
              caption='Disabling means you will no longer receive feedback for any asset.'
              notificationType={NotificationType.ASSET_FEEDBACK}
              channels={summary.assetFeedback.channels}
            />

            <Divider />

            <Subscription
              title='Change Requests'
              description='Notifications when users request changes to specific assets'
              caption='Disabling means you will no longer receive change requests for any assets.'
              notificationType={NotificationType.ASSET_CHANGE_REQUEST}
              channels={summary.assetChangeRequest.channels}
            />

            <Divider />

            <Subscription
              title='Flagging'
              description='Notifications when users flags to specific assets'
              caption='Disabling means you will no longer receive flagging notifications for any assets'
              notificationType={NotificationType.ASSET_FLAG}
              channels={summary.assetFlag.channels}
            />
          </SubscriptionCategory>
        </VisibleForRole>

        <SubscriptionCategory title='Sharing'>
          <Subscription
            title='Link Clicks'
            description='Notifications whenever an external share is clicked'
            notificationType={NotificationType.CONTENT_ROOM_OPEN}
            channels={summary.contentRoomOpen.channels}
          />

          <Divider />

          <Subscription
            title='Asset Downloads'
            description='Notifications whenever an externally shared is downloaded'
            notificationType={NotificationType.CONTENT_ROOM_ASSET_DOWNLOADED}
            channels={summary.contentRoomAssetDownloaded.channels}
          />
        </SubscriptionCategory>


      </Stack>
    </Page>
  )
}

type SubscriptionProps = {
  title: string,
  description: string,
  caption?: string,
  notificationType: NotificationType,
  channels: NotificationChannel[]
}

function Subscription({ title, description, caption, notificationType, channels }: SubscriptionProps) {
  return (
    <Stack spacing={1}>
      <Stack spacing={.5}>
        <Typography variant='h6'>{title}</Typography>
        <Typography variant='standard' color='text.deemphasized'>{description}</Typography>
        <Typography variant='caption' color='warning.dark'>{caption}</Typography>
      </Stack>
      <Box>
        <SubscriptionChannelEditButton notificationType={notificationType} channels={channels} />
      </Box>
    </Stack>
  )
}

function SubscriptionCategory({ title, children }: { title: string, children: ReactNode }) {
  const theme = useTheme()
  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        overflow: 'hidden',
      }}
    >
      <Box
        p={2}
        sx={{
          background: theme.palette.background.paper,
        }}
      >
        <Typography variant='h6'>{title}</Typography>
      </Box>
      <Divider />
      <Stack p={2} spacing={3}>
        {children}
      </Stack>
    </Box>

  )
}