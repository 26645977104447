import api from '@api'
import {
  FullUserSubscriptionResponse,
  NotificationChannel,
  NotificationType,
  UserSubscriptionSummaryResponse,
} from '../@types/notifications.ts'

export async function getNotificationSubscriptionSummary(){
  const response = await api.get<UserSubscriptionSummaryResponse>("/api/v1/notifications/user-subscriptions/summary")
  return response.data
}

export async function upsertNotificationSubscription(type: NotificationType, channels: NotificationChannel[]){
  const response = await api.put<FullUserSubscriptionResponse>(`/api/v1/notifications/user-subscriptions/${type}`, { channels })
  return response.data
}