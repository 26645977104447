import { Box, Button, Divider, Popover, Stack, Typography } from '@mui/material'
import { NotificationChannel, NotificationChannelNames, NotificationType } from '../../@types/notifications.ts'
import Iconify from '../Iconify.tsx'
import { useUpsertNotificationSubscription } from '../../hooks/useNotificationSubscriptions.ts'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormProvider, RHFCheckbox } from '../hook-form'

type SubscriptionChannelEditButtonProps = {
  notificationType: NotificationType,
  channels: NotificationChannel[]
}

// eventually we'll have all the channels in here
type ChannelFormValues = {
  email: boolean
}

const getFormDefaults = (channels: NotificationChannel[]) => ({
  email: Boolean(channels.find(it => it == NotificationChannel.EMAIL)),
})

export function SubscriptionChannelEditButton({ notificationType, channels }: SubscriptionChannelEditButtonProps) {
  // hooks
  const { mutateAsync: upsertNotificationType } = useUpsertNotificationSubscription()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const methods = useForm<ChannelFormValues>({
    defaultValues: getFormDefaults(channels),
  })
  const { handleSubmit, reset } = methods

  // effects
  useEffect(() => reset(getFormDefaults(channels)), [notificationType, channels])

  // handlers
  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const handleCancel = () => handleClose()
  const handleSave = (data: ChannelFormValues) => {
    const newChannels = [
      data.email ? NotificationChannel.EMAIL : null
    ].filter(it => it != null)
    upsertNotificationType({ type: notificationType, channels: newChannels })
    handleClose()
  }

  // calculated props
  const display = channels.length ? channels.map(it => NotificationChannelNames[it]).join(', ') : 'Never'
  const open = Boolean(anchorEl)
  const id = open ? 'new-filter-popover' : undefined

  return (
    <>
      <Button
        variant='outlined'
        color='inherit'
        onClick={handleButtonClick}
      >
        <Stack direction='row' spacing={1}>
          <Typography variant='small' color='text.deemphasized'>Notify me:</Typography>
          <Typography variant='smallHighlight'>{display}</Typography>
          <Iconify icon='ph:pencil' />
        </Stack>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: { minWidth: 240 },
          },
        }}
      >
        <Stack direction='row' spacing={4} sx={{ p: 2 }} alignItems='center'>
          <Typography variant='h4'>Select notification channels</Typography>
          <Iconify icon='ph:x' />
        </Stack>

        <Divider />

        <Box p={2}>
          <FormProvider methods={methods}>
            <Stack
              direction="column"
              spacing={1}
            >
              <RHFCheckbox name="email" label={<Typography variant="small">Email</Typography>} />
            </Stack>
          </FormProvider>
        </Box>

        <Divider />

        <Stack
          direction='row'
          alignItems='center'
          justifyContent='end'
          spacing={1}
          sx={{ p: 2 }}
        >
          <Button
            size='small'
            variant='outlined'
            onClick={handleCancel}
          >Cancel</Button>
          <Button
            size='small'
            color='primary'
            variant='contained'
            onClick={handleSubmit(handleSave)}
          >Save</Button>
        </Stack>
      </Popover>
    </>
  )
}