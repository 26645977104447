import { ContentRoomPalette } from '../../@types/hosted-content-room'
import { ReactNode, useMemo } from 'react';
import { alpha, createTheme, ThemeProvider, useTheme } from '@mui/material/styles'
import { SimplePaletteColorOptions } from '@mui/material/styles/createPalette'
import componentsOverride from '../../theme/overrides'

type ContentRoomThemeProviderProps = {
  palette: ContentRoomPalette,
  children: ReactNode
}

export function ContentRoomThemeProvider({ palette: contentRoomPalette, children }: ContentRoomThemeProviderProps) {
  const defaultTheme = useTheme()
  const theme = useMemo(
    () => {
      const primaryColor = contentRoomPalette.primary
      const secondaryColor = contentRoomPalette.secondary
      const primaryOverride = primaryColor == 'default' || !isValidColor(primaryColor) ?
        defaultTheme.palette.primary :
        { main: primaryColor } as SimplePaletteColorOptions
      const secondaryOverride = contentRoomPalette.secondary == 'default' || !isValidColor(secondaryColor) ?
        defaultTheme.palette.secondary :
        { main: secondaryColor } as SimplePaletteColorOptions
      const options = {
        ...defaultTheme,
        palette: {
          ...defaultTheme.palette,
          primary: primaryOverride,
          secondary: secondaryOverride,
          background: {
            ...defaultTheme.palette.background,
            default: defaultTheme.palette.grey['900'],
            paper: defaultTheme.palette.grey['800']
          }
        },
        customShadows: {
          ...defaultTheme.customShadows,
          primary: `0 8px 16px 0 ${alpha(primaryOverride.main, 0.24)}`,
        },
      }

      const theme = createTheme(options)
      theme.components = componentsOverride(theme);

      // extra component overrides
      theme.components = {
        ...theme.components,

        // override the
        MuiAppBar: {
          styleOverrides: {
            root: {
              background: theme.palette.background.default,
              borderBottom: `1px solid ${theme.palette.divider}`
            }
          }
        }
      }

      return theme
    }, [contentRoomPalette, defaultTheme])


  return <ThemeProvider theme={theme}>{children}</ThemeProvider>

}

function isValidColor(color: string){
  if(!color) return false
  if(color.charAt(0) != "#") return false
  if(color.length != 4 && color.length != 7) return false
  return true
}