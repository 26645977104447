import Typography from '@mui/material/Typography'
import { Box, Grid, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Iconify from '../Iconify.tsx'
import * as React from 'react'

type MassetBotSuggestionsProps = {
  onSuggestionClick?: (suggestion: string) => void
}
export function MassetBotSuggestions({ onSuggestionClick }: MassetBotSuggestionsProps) {
  return (
    <Box>
      <Stack direction='row' alignItems='center' spacing={1}>
        <Iconify icon='ph:lightbulb' color='info.main' />
        <Typography variant='smallHighlight'>Try these ideas:</Typography>
      </Stack>
      <Grid spacing={1} container mt={1}>
        <Grid item xs={6}>
          <Suggestion
            icon={<Iconify color='#cb8bd0' sx={{height: 32, width: 32}} icon='ph:envelope' />}
            text='Write me a sales email introducing this asset to a prospect.'
            onSuggestionClick={onSuggestionClick}
          />
        </Grid>
        <Grid item xs={6}>
          <Suggestion
            icon={<Iconify color='#76d0eb' sx={{height: 32, width: 32}} icon='ph:messenger-logo' />}
            text='Help me make a social media post about this piece of content.'
            onSuggestionClick={onSuggestionClick}
          />
        </Grid>
        <Grid item xs={6}>
          <Suggestion
            icon={<Iconify color='#8bd09e' sx={{height: 24, width: 24}} icon='ph:list-bullets' />}
            text='What does this content talk about?'
            onSuggestionClick={onSuggestionClick}
          />
        </Grid>
        <Grid item xs={6}>
          <Suggestion
            icon={<Iconify color='#b97311' sx={{height: 24, width: 24}} icon='ph:seal-question' />}
            text="What are some FAQ's for this content?"
            onSuggestionClick={onSuggestionClick}
          />
        </Grid>
        <Grid item xs={6}>
          <Suggestion
            icon={<Iconify color='#cb8bd0' sx={{height: 24, width: 24}} icon='ph:info' />}
            text='What are the most important parts of this content?'
            onSuggestionClick={onSuggestionClick}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

type SuggestionProps = {
  icon: React.ReactNode
  text: string
  onSuggestionClick?: (suggestion: string) => void
}
function Suggestion({ icon, text, onSuggestionClick }: SuggestionProps ) {
  const theme = useTheme()
  const handleClick = () => onSuggestionClick && onSuggestionClick(text)
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{
        padding: 1,
        cursor: 'pointer',
        borderRadius: 1,
        border: `1px solid #ffffff22`,
        transition: 'background 100ms ease-in-out',
        ':hover': {
          background: theme.palette.background.paper,
        },
      }}
      onClick={handleClick}
    >
      {icon}
      <Typography variant='small' color={'text.deemphasized'}>{text}</Typography>
    </Stack>
  )
}