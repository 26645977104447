import { Stack, Table, TableBody, TableCell, TableRow } from '@mui/material'
import ShareAnalyticsPagination from './ShareAnalyticsPagination.tsx'
import ShareAnalyticsFilterBar from './ShareAnalyticsFilterBar.tsx'
import ShareAnalyticsTableHead from './ShareAnalyticsTableHead.tsx'
import EmptyContent from '../../EmptyContent.tsx'
import { useShareAnalytics } from '../../../hooks/analytics/useShareAnalytics.ts'
import { ShareAnalyticsSkeletonTableRow } from './ShareAnalyticsSkeletonTableRow.tsx'
import { ShareAnalyticsTableRow } from './ShareAnalyticsTableRow.tsx'

export function ShareAnalyticsTable() {
  return (
    <Stack direction='column' spacing={2}>
      <ShareAnalyticsFilterBar />
      <Table stickyHeader>
        <ShareAnalyticsTableHead />
        <ShareAnalyticsTableBody />
      </Table>
      <ShareAnalyticsPagination />
    </Stack>
  )
}

function ShareAnalyticsTableBody() {
  const { data, isLoading } = useShareAnalytics()

  if (isLoading) {
    const toRender = Array.from(Array(10).keys())
    return (
      <TableBody>
        {toRender.map((it, idx) => {
          return <ShareAnalyticsSkeletonTableRow key={it} rowNum={idx} />
        })}
      </TableBody>
    )
  }

  return (
    <TableBody>
      {
        data?.results.map((it, idx) => <ShareAnalyticsTableRow key={it.contentRoomId} share={it} rowNum={idx} />)
      }
      {
        data?.results.length == 0 && (
          <TableRow>
            <TableCell colSpan={3}>
              <EmptyContent
                title='No results'
                description='No assets matched your filters'
              />
            </TableCell>
          </TableRow>

        )
      }
    </TableBody>
  )
}