import { StripedTableRow } from '../common/StripedTableRow.tsx'
import { Skeleton, Stack, TableCell, Typography } from '@mui/material'
import Iconify from '../../Iconify.tsx'
import { NumberTableCell } from '../common/NumberTableCell.tsx'

export function ShareAnalyticsSkeletonTableRow({ rowNum }: { rowNum: number }) {
  const isStriped = rowNum % 2 == 0
  return (
    <StripedTableRow isStriped={isStriped}>
      <TableCell sx={{ py: 1.5, height: 72 }}>
        <Stack direction='row' spacing={2} alignItems='center' sx={{ height: 48 }}>
          <Iconify
            icon='eva:chevron-right-fill'
            sx={{
              fontSize: 20,
              transition: '100ms all ease-in-out',
              cursor: 'pointer',
            }}
          />
          <Skeleton variant='circular' sx={{ width: 32, height: 32 }} />
          <Typography variant='smallHighlight' mb={1} component='div'>
            <Skeleton variant='text' sx={{ width: 240, height: 24 }} />
          </Typography>
        </Stack>
      </TableCell>
      <NumberTableCell>
        <Stack direction='row' justifyContent='end'>
          <Skeleton variant='text' sx={{ width: 25 }} component={Stack} />
        </Stack>
      </NumberTableCell>
      <NumberTableCell>
        <Stack direction='row' justifyContent='end'>
          <Skeleton variant='text' sx={{ width: 25 }} />
        </Stack>
      </NumberTableCell>
    </StripedTableRow>
  )
}
