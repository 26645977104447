export enum NotificationChannel {
  EMAIL = 1,
  SMS = 2,
  SLACK = 3,
  APP = 4
}

export enum NotificationType {
  ASSET_NEW_REQUEST = 1,
  ASSET_FEEDBACK = 2,
  ASSET_CHANGE_REQUEST = 3,
  ASSET_FLAG = 4,
  CONTENT_ROOM_OPEN = 100,
  CONTENT_ROOM_ASSET_DOWNLOADED = 101,
}

export const NotificationChannelNames = {
  [NotificationChannel.EMAIL]: "By Email",
  [NotificationChannel.SMS]: "By SMS",
  [NotificationChannel.SLACK]: "In Slack",
  [NotificationChannel.APP]: "In Masset",
}


export type UserSubscriptionSummaryResponse = {
  assetNewRequest: SimpleUserSubscription,
  assetFeedback: SimpleUserSubscription,
  assetChangeRequest: SimpleUserSubscription,
  assetFlag: SimpleUserSubscription,
  contentRoomOpen: SimpleUserSubscription,
  contentRoomAssetDownloaded: SimpleUserSubscription,
}

export type SimpleUserSubscription = {
  notificationType: NotificationType,
  channels: NotificationChannel[]
}

export type FullUserSubscriptionResponse = {
  notificationUserSubscriptionId: string,
  notificationType: NotificationType,
  channels: NotificationChannel[]
}