import * as React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material'
import Iconify from '../Iconify.tsx'
import { alpha, useTheme } from '@mui/material/styles'

export function NewConversationSuggestions() {
  return (
    <Box textAlign="left">
      <Typography variant='standard' paragraph>Example Conversations:</Typography>
      <Grid spacing={2} container>
        <Grid item xs={3}>
          <Suggestion
            icon={<Iconify color='#cb8bd0' fontSize={24} icon='ph:quotes' />}
            text='Find me some quotes about...'
            status='ready'
          />
        </Grid>

        <Grid item xs={3}>
          <Suggestion
            icon={<Iconify color='#76d0eb' fontSize={24} icon='ph:file-magnifying-glass' />}
            text='Can you find me our <content>?'
            status='ready'
          />
        </Grid>

        <Grid item xs={3}>
          <Suggestion
            icon={<Iconify color='#d08b98' fontSize={24} icon='ph:building-office' />}
            text='Which industries do we serve?'
            status='ready'
          />
        </Grid>

        <Grid item xs={3}>
          <Suggestion
            icon={<Iconify color='#8bd09e' fontSize={24} icon='ph:certificate' />}
            text='Are we SOC2 compliant?'
            status='ready'
          />
        </Grid>

        <Grid item xs={4}>
          <Suggestion
            icon={<Iconify color='#b97311' fontSize={24} icon='ph:currency-circle-dollar' />}
            text='What content is converting best?'
            status='coming'
          />
        </Grid>

        <Grid item xs={4}>
          <Suggestion
            icon={<Iconify color='#e2c541' fontSize={24} icon='ph:chart-line-up' />}
            text='What content is most popular on the Sales team?'
            status='coming'
          />
        </Grid>


        <Grid item xs={4}>
          <Suggestion
            icon={<Iconify color='#336699' fontSize={24} icon='ph:users-three' />}
            text="What's revelant for <prospect>?"
            status='coming'
          />
        </Grid>

      </Grid>
    </Box>
  )
}

function Suggestion({ icon, text, status }: { icon: React.ReactNode, text: string, status: 'ready' | 'coming' }) {
  const theme = useTheme()
  const statusText = status == 'ready' ? 'Ready Now' : 'Coming Soon'
  const statusColor = status == 'ready' ? alpha(theme.palette.success.main, .65) : alpha(theme.palette.info.main, .75)
  return (
    <Box
      sx={{
        padding: 2,
        // cursor: 'pointer',
        borderRadius: 1,
        border: `1px solid #ffffff22`,
        textAlign: 'left',
        transition: 'background 100ms ease-in-out',
        ':hover': {
          background: theme.palette.background.paper,
        },
      }}
    >
      <Box>{icon}</Box>
      <Typography variant='standard' color={'text.deemphasized'} paragraph
                  sx={{ minHeight: '2.5rem' }}>{text}</Typography>
      <Box
        sx={{
          display: 'inline-block',
          border: `1px solid #ffffff22`,
          borderRadius: 1,
          paddingX: 1,
          paddingY: 0,
          borderColor: statusColor,
        }}
      >
        <Typography variant='small' color={statusColor}>{statusText}</Typography>
      </Box>
    </Box>
  )
}