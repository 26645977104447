import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
} from '@mui/material'
import Iconify from '../../Iconify.tsx'
import { useState } from 'react';
import Typography from '@mui/material/Typography'
import { OAUTH_PROVIDER_NAMES, OauthProvider } from '../../../@types/oauth.ts'
import { IntegrationLogo } from './IntegrationLogo.tsx'
import { useOauthPopup } from '../../../hooks/useOauth.ts'
import { useQueryClient } from '@tanstack/react-query'

export function AddIntegrationAccountPopover() {
  const [open, setOpen] = useState<boolean>(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleOnOauthStart = () => setOpen(false)
  return (
    <>
      <Button
        variant='contained'
        size='small'
        startIcon={<Iconify icon='ph:plus' />}
        onClick={handleOpen}
      >
        Add Account
      </Button>

      <Dialog
        open={open}
        maxWidth='md'
        fullWidth={true}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography variant='h2' paragraph mb={3}>Add Account</Typography>
          <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={() => setOpen(false)}>
              <Iconify icon='eva:close-fill' />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={4}><IntegrationCard provider={OauthProvider.GOOGLE_DRIVE}
                                               onOauthSuccess={handleOnOauthStart} /></Grid>
            <Grid item xs={4}><IntegrationCard provider={OauthProvider.GOOGLE_YOUTUBE_DATA}
                                               onOauthSuccess={handleOnOauthStart} /></Grid>
            <Grid item xs={4}><IntegrationCard provider={OauthProvider.HUBSPOT}
                                               comingSoon={true}
                                               onOauthSuccess={handleOnOauthStart} /></Grid>
          </Grid>
        </DialogContent>

      </Dialog>
    </>
  )
}

function IntegrationCard({ provider, comingSoon = false, onOauthSuccess }: { provider: OauthProvider, comingSoon?: boolean, onOauthSuccess?: () => void }) {
  const queryClient = useQueryClient()
  const providerName = OAUTH_PROVIDER_NAMES[provider] || ''
  const handleOauthSuccess = () => {
    queryClient.invalidateQueries(['accounts'])
    onOauthSuccess && onOauthSuccess()
  }
  const { trigger } = useOauthPopup({ onSuccess: handleOauthSuccess })
  const handleClick = () => trigger(provider)

  return (
    <Card
      component={Paper}
      sx={{
        backgroundColor: 'background.neutral',
        pointerEvents: comingSoon ? 'none' : 'inherit',
        cursor: comingSoon ? 'none' : 'pointer',
      }}
      onClick={handleClick}
    >
      {comingSoon && (
        <Chip label='Coming Soon' color='info' sx={{ position: 'absolute', right: 10, top: 10, zIndex: 999 }} />)}
      <CardContent sx={{ opacity: comingSoon ? .35 : 1 }}>
        <Stack spacing={3}>
          <Stack direction='row' justifyContent='center'>
            <IntegrationLogo
              provider={provider}
              size='medium'
              objectPosition='center'
            />
          </Stack>
          <Box textAlign='center'>
            <Typography variant='h3'>{providerName}</Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}